import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify, {
  theme: {
    primary: '#000',
    accent: '#FFF',
    secondary: '#555'
  }
})

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#000',
        accent: '#FFF',
        secondary: '#555'
      },
      dark: {
        primary: '#000',
        accent: '#FFF',
        secondary: '#555'
      }
    }
  }
})
