import Vue from 'vue'
import Router from 'vue-router'
import HomePage from './components/Home.vue'
const EetLijst = () => import(/* webpackChunkName: "eetlijst" */ './components/Eetlijst.vue')

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage
    },
    {
      path: '/eetlijst',
      name: 'eetlijst',
      component: EetLijst
    },
    {
      path: '/stack',
      beforeEnter () {
        location.href = 'https://stack.momus.nl/'
      }
    },
    {
      path: '/data',
      beforeEnter () {
        location.href = 'https://stack.momus.nl/'
      }
    },
    {
      path: '/fire',
      beforeEnter () {
        location.href = 'https://momus.nl/api/firemomus/'
      }
    },
    {
      path: '/firemomus',
      beforeEnter () {
        location.href = 'https://momus.nl/api/firemomus/'
      }
    },
    {
      path: '/fireman',
      beforeEnter () {
        location.href = 'https://momus.nl/api/firemomus/'
      }
    },
    {
      path: '/foto',
      beforeEnter () {
        location.href = 'https://oud.momus.nl/foto'
      }
    },
    {
      path: '/oudeetlijst',
      beforeEnter () {
        location.href = 'https://docs.google.com/spreadsheets/d/1ikcQKro0vxXH32g5ZEhB202CVI1O0crsvwR0M40lqJc/edit?usp=sharing'
      }
    },
    {
      path: '/oud',
      beforeEnter () {
        location.href = 'https://oud.momus.nl/'
      }
    }
  ]
})
