<template>
  <v-app>
    <v-content>
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    color: #2c3e50;

    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    height: 100vh;

    overflow-y: auto;
  }

  .view {
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    height: 100vh;
  }

  html {
    overflow-y: auto !important;
  }
</style>
