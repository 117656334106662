<template>
  <v-container fill-height>
    <v-layout
      align-center
      justify-center
      row
      fill-height
    >
      <v-flex
        xs12
        sm6
        md5
        lg5
        xl4
      >
        <v-card>
          <v-card-title>
            Navigation
          </v-card-title>

          <v-card-text>
            <v-list dense>
              <v-list-item>
                  <v-btn
                    block
                    dense
                    to="/fire"
                    dark
                  >
                    Fire Momus
                  </v-btn>
              </v-list-item>
              <v-list-item>
                  <v-btn
                    block
                    dense
                    dark
                  >
                    Oude website (offline)
                  </v-btn>
              </v-list-item>
              <v-list-item>
                  <v-btn
                    block
                    dense
                    to="/stack"
                    dark
                  >
                    Data
                  </v-btn>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'HomePage'
}
</script>

<style scoped>

</style>
